import * as React from 'react';
import { Grid, Cell } from 'baseui/layout-grid';
import { Label1, Label2, Label3, Label4 } from 'baseui/typography';
import { useStyletron } from 'baseui';
import Overflow from 'baseui/icon/overflow';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu, NestedMenus } from 'baseui/menu';
import { sourceByType } from '../../util';
import {
  LabelMedium,
} from 'baseui/typography';
import { Skeleton } from 'baseui/skeleton'
import Button from '../styledButton';
import './attribute.css';
import { gridPaddingOverrides, cellPaddingOverrides } from '../overrides';
import { client, getServerUrl } from '../../apollo/client';
import { getTags, createTag, updateTag } from '../../quries';
import { Search } from 'baseui/icon';
import { Input } from 'baseui/input';
import { FileUploader } from 'baseui/file-uploader';
import { showAlert } from '../../redux/actions/appBasicControls';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import { useDispatch } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { arrayMove } from 'baseui/dnd-list';
import axios from 'axios';
import PortalAwareItem from '../home-components/portal';
import { Select } from 'baseui/select';

const grid = 8;
const DEFAULT_METADATA_KEYS = process.env.TAGS_METADATA_KEYS || '';

const getItemStyle = (isDragging, draggableStyle, tag) => ({
  userSelect: "none",
  cursor: 'pointer',
  margin: '10px',
  borderRadius: '10px',
  cursor: 'pointer',
  backgroundSize: 'cover',
  // backgroundImage: tag.cover_image ? `url('${sourceByType(tag.cover_image, 'full', 'full')}')` : 'linear-gradient(#33339c, #785c61)',
  background: isDragging ? "white" : "grey",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: "transparent",
  width: '100%'
});


const Tags = ({ organization, toaster }) => {
  const [css, theme] = useStyletron();
  const [tags, setTags] = React.useState([]);
  const organization_id = organization.id
  const [popActive, setPopActive] = React.useState({});
  const [isAddNewLoading, setAddNewLoading] = React.useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = React.useState(false);
  const [isLoadingTags, setIsLoadingTags] = React.useState(false);
  const [addOpen, isAddOpen] = React.useState(false);
  const [updateOpen, isUpdateOpen] = React.useState(false);
  const [deleteOpen, isDeleteOpen] = React.useState(false);
  const [tagName, setTagName] = React.useState('')
  const [type, setType] = React.useState('');
  const [metadata, setMetadata] = React.useState(null);
  const [coverImage, setCoverImage] = React.useState(null)
  const [tagId, setTagId] = React.useState(null)
  const [searchTxt, setSearchTxt] = React.useState('');
  const [marker_icon_url, set_marker_icon_url] = React.useState('');

  const [newTags, setNewTags] = React.useState([]);


  const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);
  const [importFile, setImportFile] = React.useState(null);
  const [importLoading, setImportLoading] = React.useState(false);

  const getMetadataInput = () => {
    let defaultKeys = DEFAULT_METADATA_KEYS.split(',').map(el => el.trim()).reduce((a, b) => {
      a[b] = '';
      return a;
    }, {});
    let m = metadata || '{}';
    let i = [];
    try {
      m = JSON.parse(m);
    } catch (e) {
      m = {};
    };
    m = { ...defaultKeys, ...m };
    Object.keys(m).forEach(k => {
      i.push({
        key: k, value: m[k], display: k.replace('_', ' ')
      })
    });
    i = i.filter(el => el.display);
    return i;
  };

  const setValueToMetadata = (key, value) => {
    let m = metadata || '{}';
    try {
      m = JSON.parse(m);
      m[key] = value;
      setMetadata(JSON.stringify(m))
    } catch (e) {

    }
  };


  React.useEffect(() => {
    makeNewtags()
  }, [tags])

  const makeNewtags = () => {
    const columnCounts = 8;
    let newTags = []
    let temp = [];
    const sortTags = [...tags].sort((a, b) => a.position - b.position)
    sortTags.map((tag, index) => {
      temp.push({ ...tag, index: index });
      if ((index + 1) % columnCounts === 0) {
        newTags.push(temp)
        temp = [];
      }
    });
    if (temp.length > 0) newTags.push(temp);
    setNewTags(newTags)
  }

  const loadTags = (loading = false) => {
    if (!loading) {
      setIsLoadingTags(true);
    }
    client.query({
      query: getTags(organization_id),
      fetchPolicy: 'network-only'
    }).then(({ data }) => {
      setIsLoadingTags(false);
      setIsUpdateLoading(false);
      setAddNewLoading(false);

      setTags([...data.tags_org].sort((a, b) => a.position - b.position));
    })
  };

  const onClose = () => {
    isAddOpen(false);
    isUpdateOpen(false)
    isDeleteOpen(false)
    setCoverImage(null)
    setTagName('')
    setTagId('')
    setType('')
    setMetadata(null)
    set_marker_icon_url('');
    tags.filter(item => searchTxt ? item.name.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1 : true).map((item, index) => setPopActive({ ...popActive, [index]: true }))
  }

  const showMessage = () => {
    // dispatch(
    //   showAlert({
    //     msg: 'The tag changes have been saved.',
    //     error: false,
    //   })
    // );
  }

  const onSave = async () => {
    setIsUpdateLoading(true);
    await client.mutate({
      mutation: updateTag,
      variables: {
        id: tagId,
        name: tagName,
        cover_image: Array.isArray(coverImage) ? coverImage : null,
        organization_id: organization_id,
        type,
        metadata,
        marker_icon_url
        // let toastKey = toaster.info('Saving tags...');
        // await Promise.all(tags.filter(e => {
        //   return e.id == e.name ? e.isDeleted ? false : true : true
        // }).map((el) => {
        //   if (el.id == el.name) {
        //     el.id = null
      }
    })
    loadTags(false);
    onClose();
    showMessage();
  };

  const onReorder = async (items) => {
    setIsUpdateLoading(true);
    await Promise.all(items.map(async item => {
      const { id, position } = item;
      await client.mutate({
        mutation: updateTag,
        variables: {
          id,
          position,
          organization_id: organization_id,
        }
      })
    }));
    // toaster.clear(toastKey);
    loadTags();
    onClose();
    showMessage();
  }

  const onDelete = async () => {
    setIsUpdateLoading(true);
    await client.mutate({
      mutation: updateTag,
      variables: {
        id: tagId,
        organization_id: organization_id,
        isDeleted: true
      }
    })
    loadTags();
    onClose();
    showMessage();
  };

  const onAdd = async () => {
    setAddNewLoading(true);
    await client.mutate({
      mutation: createTag,
      variables: {
        name: tagName,
        organization_id: organization_id,
        cover_image: coverImage,
        type,
        metadata,
        marker_icon_url
      }
    })
    loadTags();
    onClose();
    showMessage();
  }

  React.useEffect(() => {
    loadTags();
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source, ...rest }) => {
    if (!destination) {
      return;
    }

    let newArr = reorder(
      [...tags],
      source.index,
      destination.index
    );
    newArr = newArr.map((el, i) => {
      return {
        ...el,
        position: i
      }
    });
    setTags(newArr)
    onReorder(newArr)
  }

  if (isLoadingTags) {
    return (
      <div style={{ width: '100%', }}>
        <div style={{ marginLeft: '2rem', marginTop: '1rem' }}>
          {new Array(6).fill('').map(item => {
            return (
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: '2rem' }}>
                {new Array(1).fill('').map(item => <Skeleton
                  width="90%"
                  height="105px"
                  animation
                  overrides={{
                    Root: {
                      style: {
                        marginRight: '60px'
                      },
                    },
                  }}
                />)}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const renderTagTableItem = (provided, snapshot, index, tag) => {
    return <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        width: '100%',
        height: '64px',
        background: snapshot.isDragging ? 'rgb(128 128 128 / 40%)' : 'rgb(234, 234, 234)',
        padding: '16px',
        borderRadius: '10px',
        marginBottom: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        ...provided.draggableProps.style
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', minWidth: '100px' }}>
          <img
            className={css({
              height: '44px',
              backgroundSize: 'cover !important',
              position: 'relative',
              float: 'left',
              borderRadius: '8px',
              marginRight: '16px',
              marginBottom: '0px',
            })}
            src={tag.cover_image ? sourceByType(tag.cover_image, 'full', 'full') : 'https://img.icons8.com/plumpy/48/000000/tag.png'}
          />
        </div>
        <Label3>{tag.name}</Label3>
      </div>


      <div style={{ display: 'flex', alignItems: 'center' }}>
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ cursor: 'pointer', opacity: 1, marginRight: '16px' }}
          onClick={() => {
            setTagId(tag.id)
            setTagName(tag.name)
            setCoverImage(tag.cover_image)
            setType(tag.type);
            isUpdateOpen(true)
            setMetadata(tag.metadata)
            set_marker_icon_url(tag.marker_icon_url)
          }}
        >
          <path
            d="M19.2022 12.0725C19.243 11.7287 19.2701 11.3713 19.2701 11C19.2701 10.6287 19.243 10.2713 19.1886 9.9275L21.4859 8.1125C21.6898 7.9475 21.7442 7.645 21.6219 7.41125L19.4469 3.6025C19.3109 3.355 19.0255 3.2725 18.7808 3.355L16.0756 4.455C15.5047 4.015 14.9065 3.6575 14.2404 3.3825L13.8326 0.4675C13.7918 0.1925 13.5607 0 13.2889 0H8.93884C8.66696 0 8.44946 0.1925 8.40868 0.4675L8.00086 3.3825C7.33477 3.6575 6.72304 4.02875 6.1657 4.455L3.46053 3.355C3.21584 3.25875 2.93037 3.355 2.79443 3.6025L0.619417 7.41125C0.483478 7.65875 0.537854 7.9475 0.755355 8.1125L3.05271 9.9275C2.99834 10.2713 2.95755 10.6425 2.95755 11C2.95755 11.3575 2.98474 11.7287 3.03912 12.0725L0.741761 13.8875C0.537853 14.0525 0.483478 14.355 0.605823 14.5887L2.78083 18.3975C2.91677 18.645 3.20224 18.7275 3.44693 18.645L6.1521 17.545C6.72304 17.985 7.32117 18.3425 7.98727 18.6175L8.39509 21.5325C8.44946 21.8075 8.66696 22 8.93884 22H13.2889C13.5607 22 13.7918 21.8075 13.819 21.5325L14.2268 18.6175C14.8929 18.3425 15.5047 17.9713 16.062 17.545L18.7672 18.645C19.0119 18.7413 19.2973 18.645 19.4333 18.3975L21.6083 14.5887C21.7442 14.3412 21.6898 14.0525 21.4723 13.8875L19.2022 12.0725ZM11.1139 15.125C8.87087 15.125 7.0357 13.2688 7.0357 11C7.0357 8.73125 8.87087 6.875 11.1139 6.875C13.3568 6.875 15.192 8.73125 15.192 11C15.192 13.2688 13.3568 15.125 11.1139 15.125Z"
            fill={'black'}
          />
        </svg>
        <img src="https://img.icons8.com/material-rounded/24/000000/filled-trash.png" style={{
          marginBottom: '0px', cursor: 'pointer'
        }} onClick={() => {
          setTagId(tag.id);
          isDeleteOpen(true);
        }} />
      </div>


    </div>
  }

  const renderTagItem = (provided, snapshot, index, tag) => {
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(
          snapshot.isDragging,
          provided.draggableProps.style,
          tag
        )}
      >
        <div style={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', background: 'rgba(0,0,0,0.1)', borderRadius: '10px' }}></div>
        <Label3 style={{ position: 'absolute', left: '10px', bottom: '10px', margin: 0, color: 'white' }}>{tag.position + 1}. {tag.name}</Label3>
        <div className={css({
          position: 'absolute',
          width: theme.sizing.scale1200,
          height: theme.sizing.scale1200,
          right: theme.sizing.scale300,
          top: theme.sizing.scale0,
        })}
        >
          <StatefulPopover
            initialState={{ isOpen: false }}
            dismissOnEsc={true}
            dismissOnClickOutside={true}
            overrides={{
              Body: {
                style: {
                  zIndex: 3
                }
              }
            }}
            content={() => {
              if (popActive[index]) return null
              return <NestedMenus>
                <StatefulMenu
                  items={[
                    { label: 'Update' },
                    { label: 'Delete' },
                  ]}
                  overrides={{
                    List: {
                      style: {
                        width: '120px',
                        maxHeight: '1000px',
                        overflow: 'auto',
                      },
                    },
                  }}
                  onItemSelect={({ item, event }) => {
                    setPopActive({ ...popActive, [index]: true })
                    if (item.label === 'Update') {
                      setTagId(tag.id)
                      setTagName(tag.name)
                      setCoverImage(tag.cover_image)
                      isUpdateOpen(true)
                    }
                    if (item.label === 'Delete') {
                      setTagId(tag.id)
                      isDeleteOpen(true)
                    };
                  }}
                />
              </NestedMenus>
            }}
            placement={PLACEMENT.top}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation()
              setPopActive({ ...popActive, [index]: false })
            }}
          >
            <Overflow
              className={css({
                top: '-10px',
                right: '-7px',
                color: 'white !important',
                position: 'absolute',
                cursor: 'pointer',
                marginBottom: '0px',
              })}
              size={36}
            />
          </StatefulPopover>
        </div>
      </div>
    )
  }

  const renderUpdateModal = () => {
    return (
      <Modal
        onClose={onClose}
        closeable
        isOpen={addOpen || updateOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>{updateOpen ? 'Update Tag' : 'Add Tag'}</ModalHeader>
        <ModalBody style={{ padding: 0 }}>
          <Grid overrides={{
            Grid: {
              style: () => ({
                padding: '0px !important',
              }),
            },
          }}>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    padding: '0px !important',
                  }),
                },
              }}
            >
              <Label2 style={{ marginBottom: '0.5rem' }}>Name</Label2>
              <Input
                value={tagName}
                onChange={e => setTagName(e.target.value)}
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    padding: '0px !important',
                  }),
                },
              }}
            >
              <Label2 style={{ marginBottom: '0.5rem', marginTop: '0.5rem'  }}>Map Marker URL</Label2>
              <Input
                value={marker_icon_url}
                onChange={e => set_marker_icon_url(e.target.value)}
                clearOnEscape
              />
            </Cell>
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    padding: '0px !important',
                  }),
                },
              }}
            >
              <Label2 style={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>Type</Label2>
              <Select
                options={[
                  { label: '', id: '' },
                  { label: 'Card', id: 'card' },
                  { label: 'Category', id: 'category' },
                  { label: 'Channel', id: 'channel' },
                  { label: 'Locations', id: 'Locations' },
                  { label: 'Teams', id: 'Teams' },
                ]}
                value={[
                  { label: '', id: '' },
                  { label: 'Card', id: 'card' },
                  { label: 'Category', id: 'category' },
                  { label: 'Channel', id: 'channel' },
                  { label: 'Locations', id: 'Locations' },
                  { label: 'Teams', id: 'Teams' },
                ].filter(el => el.id == type)}
                labelKey="label"
                valueKey="id"
                placeholder=''
                onChange={({ value }) => setType(value[0]?.id)}
              />
            </Cell>
            {
              getMetadataInput().length > 0 && <hr style={{ width: '100%', height: '2px', margin: 0, marginTop: '16px', marginBottom: '8px', background: '#E2E2E2' }} />
            }
            {getMetadataInput().map(el => {
              return <div style={{
                marginTop: '0.5rem', marginBottom: '0.5rem', width: '100%',
              }}><Cell
                span={[1, 12]}
                overrides={{
                  Cell: {
                    style: () => ({
                      padding: '0px !important',
                    }),
                  },
                }}
              >
                  <Label2 style={{ marginBottom: '0.5rem', textTransform: 'capitalize' }}>{el.display}</Label2>
                  <Input
                    value={el.value}
                    onChange={e => setValueToMetadata(el.key, e.target.value)}
                    clearOnEscape
                  />
                </Cell>
              </div>
            })}
            {
              getMetadataInput().length > 0 && <hr style={{ width: '100%', height: '2px', margin: 0, marginTop: '16px', marginBottom: '0px', background: '#E2E2E2' }} />
            }
            <Cell
              span={[1, 12]}
              overrides={{
                Cell: {
                  style: () => ({
                    padding: '0px !important',
                    marginTop: '1rem'
                  }),
                },
              }}
            >
              <Label2 style={{ marginBottom: '0.5rem' }}>Cover Image</Label2>
              {coverImage && <img src={Array.isArray(coverImage) ? URL.createObjectURL(coverImage[0]) : sourceByType(coverImage, 'full', 'full')} style={{ width: '100%', margin: 'auto' }}></img>}
              <FileUploader
                onDrop={(acceptedFiles, rejectedFiles) => {
                  setCoverImage(acceptedFiles);
                }}
              />
            </Cell>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={ButtonKind.tertiary} onClick={onClose}>
            Cancel
          </ModalButton>
          {addOpen && <ModalButton onClick={() => onAdd()} isLoading={isAddNewLoading}>Save</ModalButton>}
          {updateOpen && <ModalButton onClick={() => onSave()} isLoading={isUpdateLoading}>Update</ModalButton>}
        </ModalFooter>
      </Modal>
    )
  }

  const renderDeleteModal = () => {
    return (
      <Modal
        onClose={onClose}
        closeable
        isOpen={deleteOpen}
        animate
        autoFocus
        size={SIZE.default}
        role={ROLE.dialog}
      >
        <ModalHeader>Confirm</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this tag?
        </ModalBody>
        <ModalFooter>
          <ModalButton kind={ButtonKind.tertiary} onClick={onClose}>
            Cancel
          </ModalButton>
          <ModalButton onClick={onDelete} isLoading={isUpdateLoading}>Delete</ModalButton>
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <Grid overrides={gridPaddingOverrides}>
      <Cell overrides={cellPaddingOverrides} span={12}>
        <div
          className={css({
            marginBottom: '24px',
            marginTop: '1rem',
          })}
        >
          {/* <div style={{ marginLeft: '10px' }}>
            <Input
              startEnhancer={<Search
                className={css({
                  color: 'black !important',
                  cursor: 'pointer',
                })}
                size={20} />}
              value={searchTxt}
              onChange={e => setSearchTxt(e.target.value)}
              clearOnEscape
              placeholder='Search Tags'
            />
          </div> */}
          <Cell overrides={cellPaddingOverrides} span={12}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <div style={{ width: '500px' }}>
                <Input
                  startEnhancer={<Search
                    className={css({
                      color: 'black !important',
                      cursor: 'pointer',
                    })}
                    size={20} />}
                  value={searchTxt}
                  onChange={e => setSearchTxt(e.target.value)}
                  clearOnEscape
                  placeholder='Search Tags'
                />
              </div>
              {/* <LabelMedium style={{ marginLeft: '10px' }}>Browse Tags</LabelMedium> */}
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '12px' }}>
                  <Button isSelected onClick={() => { setIsImportModalOpen(true); }}>Import</Button>
                </div>
                <div style={{ marginRight: '12px' }}>
                  <Button isSelected onClick={() => {
                    const url = `${getServerUrl()}/tags/download/${organization.id}`;
                    window.open(url, '_blank');
                  }}>Export</Button>
                </div>
                <Button isSelected onClick={() => { isAddOpen(true); }}>Add</Button>
              </div>
            </div>
          </Cell>
        </div>
      </Cell>
      <DragDropContext onDragEnd={onDragEnd}>
        {/* {newTags.map((sideTags, rowIndex) => ( */}
        <Droppable droppableId={'droppable'}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {tags.filter(item => searchTxt ? item.name.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1 : true).map(({ index, ...tag }, columnIndex) => (
                <Draggable key={tag.id} draggableId={tag.id} index={columnIndex} isDragDisabled={!!searchTxt}>
                  {(provided, snapshot) => {
                    return <PortalAwareItem
                      snapshot={snapshot}
                      child={renderTagTableItem(provided, snapshot, columnIndex, tag)}
                    />
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        {/* // ))} */}
      </DragDropContext>

      {searchTxt && tags.filter(item => searchTxt ? item.name.toLowerCase().indexOf(searchTxt.toLowerCase()) > -1 : true).length == 0 && (
        <div style={{ textAlign: 'center', width: '100%', marginTop: '1rem' }}><Label2>No data found</Label2></div>
      )}

      {renderUpdateModal()}
      {renderDeleteModal()}


      <Modal
        isOpen={isImportModalOpen}
        onClose={() => {
          setIsImportModalOpen(false);
          setImportLoading(false);
          setImportFile(null);
        }}
      >
        <ModalHeader>{'Import from CSV'}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          {importFile && <LabelMedium>File Selected {importFile.name}</LabelMedium>}
          <FileUploader
            onDrop={(acceptedFiles, rejectedFiles) => {
              setImportFile(acceptedFiles[0])

            }}
          />
        </ModalBody>
        <ModalFooter>
          <ModalButton
            isLoading={importLoading}
            disabled={!importFile}
            onClick={() => {
              setImportLoading(true);
              const formData = new FormData();
              formData.append('file', importFile);
              formData.append('organization_id', organization.id);
              axios.post(`${getServerUrl()}/tags_import`, formData)
                .then(async (result) => {
                  console.log(result);
                  await loadTags();
                  setImportLoading(false);
                  setIsImportModalOpen(false);
                  setImportFile(null);
                });
            }}
          >
            {'Import'}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </Grid>
  );
};

export default Tags;
